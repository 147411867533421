import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { PATH } from '~/pages/contracts';

const Home: NextPage = () => {
  const router = useRouter();
  if (router.isReady) {
    router.replace(PATH).then();
  }
  return null;
};

export default Home;
